import './BankIdConfirmation.styles.css';
import { Button } from '@simplea/shared-ui';

import { Form, Formik, FormikProps } from 'formik';
import { BankIdConfirmationFormData, useBankIdConfirmation } from './useBankIdConfirmation';
import { participantInitialValues, scrollToError } from './utils';
import { IsPepCheckbox } from './components/IsPepCheckbox';
import { BankAccount } from './components/BankAccount';
import { NewBankId } from './components/NewBankId';

import { IdCard } from './components/IdCard';
import { PersonalInfo } from './components/PersonalInfo';
import { Success } from './components/Success';
import { BankIdData } from '../../../utils/types';
import { Box, Layout } from '../../../components';

type Props = {
    bankIdResponse: BankIdData;
    processId: string | null;
};

export const BankIdConfirmation = ({ bankIdResponse, processId }: Props) => {
    const {
        isPep,
        setIsPep,
        onSubmit,
        hasIssuer,
        participant,
        isSupportedPrefix,
        hasCitizenship,
        issueDate,
        submitted,
        phoneNumber,
        hasBirthPlace,
        handleValidate,
        isSubmitting,
        isIdentificationSuccessful,
        lovs: { banks, countries, prefixes },
    } = useBankIdConfirmation(bankIdResponse);

    return (
        <Layout
            isBankId={!isIdentificationSuccessful}
            headline={!isIdentificationSuccessful ? 'Potvrzení údajů' : ''}
            description={
                !isIdentificationSuccessful ? 'Potrvďte, prosím, aktuálnost a správnost údajů z BankID níže' : ''
            }
        >
            {isIdentificationSuccessful ? (
                <Success />
            ) : (
                <Box>
                    <Formik<BankIdConfirmationFormData>
                        enableReinitialize
                        initialValues={participantInitialValues(participant, isSupportedPrefix)}
                        onSubmit={onSubmit}
                        validateOnBlur={false}
                        validateOnChange={submitted}
                        validateOnMount={false}
                        validate={handleValidate}
                    >
                        {({ errors, touched, handleSubmit }) => (
                            <Form style={{ width: '100%' }}>
                                <div className="confirmationWrap">
                                    <PersonalInfo
                                        props={{
                                            countries,
                                            errors,
                                            hasBirthPlace,
                                            hasCitizenship,
                                            isSupportedPrefix,
                                            participant,
                                            phoneNumber,
                                            prefixes,
                                            touched,
                                        }}
                                    />
                                    <IdCard
                                        hasIssueDate={!!issueDate}
                                        hasIssuer={hasIssuer}
                                        errors={errors}
                                        participant={participant}
                                    />
                                    <BankAccount errors={errors} bankOptions={banks} />
                                    <IsPepCheckbox isPep={isPep} setIsPep={setIsPep} />
                                    <div className="confirmationItemCentered columnWithGap">
                                        <Button
                                            onClick={(e) => {
                                                e?.preventDefault();
                                                handleSubmit();
                                                scrollToError();
                                            }}
                                            loading={isSubmitting}
                                        >
                                            Potvrdit a odeslat
                                        </Button>
                                        <NewBankId processId={processId} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Box>
            )}
        </Layout>
    );
};
