import * as yup from 'yup';

import dayjs from 'dayjs';
import { regex } from './utils';
import { constants } from '../../../utils/constants';
import { ValidateFieldPhoneProps } from '../../../utils/types';
import { removeWhitespace } from '../../../utils';

export const validationSchemaDefault = (hasIssueDate: boolean) =>
    yup.object({
        birthCountry: yup.string().required(constants.REQUIRED_FIELD).nullable(),
        issuer: yup
            .string()
            .required(constants.REQUIRED_FIELD)
            .min(2, 'Délka pole musí mít alespoň 2 znaky')
            .nullable(),
        citizenship: yup.string().required(constants.REQUIRED_FIELD).nullable(),
        validateSecondCitizenship: yup.boolean(),
        secondCitizenship: yup
            .string()
            .when('validateSecondCitizenship', {
                is: true,
                then: (schema) => schema.required(constants.REQUIRED_FIELD).nullable(),
            })
            .nullable(),
        birthPlace: yup.string().required(constants.REQUIRED_FIELD).nullable(),
        bankAccountCode: yup.string().when('bankAccountNo', (bankAccountNo) => {
            if (bankAccountNo && bankAccountNo.toString().length > 0) {
                return yup.string().required(constants.REQUIRED_FIELD).nullable();
            } else if (!bankAccountNo || bankAccountNo.toString().length < 1) {
                return yup.string().max(0, 'Nelze vybrat bez čísla účtu').nullable();
            } else {
                return yup.string().notRequired();
            }
        }),
        email: yup.string().email(constants.EMAIL_FIELD).required(constants.REQUIRED_FIELD),
        phone: yup.string().required(constants.REQUIRED_FIELD),
        issueDate: !hasIssueDate
            ? yup
                  .string()
                  .required(constants.REQUIRED_FIELD)
                  .test('yearValidation', constants.REQUIRED_VALID_DATE_FIELD, (_, meta) => {
                      const yearString = meta.originalValue.toString().split(' ')[3];

                      const bottomThreshold = 2000;

                      return !(Number(yearString) < bottomThreshold) && !(Number(yearString).toString().length < 4);
                  })
                  .test('issueDateOverlap', constants.ISSUE_DATE_GT_TODAY, (value) => {
                      return !dayjs(value).isAfter(new Date());
                  })
            : yup.string().notRequired(),
    });

export const getPhoneValidationRegex = (prefix: string): string => {
    switch (prefix) {
        case '+420':
            return regex.CZECH_CELLPHONE_REGEX;
        case '+421':
            return regex.SLOVAK_CELLPHONE_REGEX;
        case '+48':
            return regex.POLAND_CELLPHONE_REGEX;
        default:
            return regex.OTHER_CELLPHONE_REGEX;
    }
};

export const validateFieldPhone = ({ phone, phonePrefix, optional }: ValidateFieldPhoneProps): string | null => {
    if ((!phone || !phonePrefix) && !optional) {
        return constants.REQUIRED_FIELD;
    }

    const regex = new RegExp(getPhoneValidationRegex(phonePrefix));
    if (phone && !regex.test(removeWhitespace(phone) || '') && !optional) {
        return constants.CELLPHONE_NUMBER;
    }

    return null;
};
