import { Field, FieldProps, FormikErrors } from 'formik';
import { DataPlaceholder } from '../../../../components';
import { formatDate } from '../../../../utils';
import { ParticipantProps } from '../../../../utils/types';
import { BankIdConfirmationFormData, BankIdConfirmationFormFields } from '../useBankIdConfirmation';
import { getCardType } from '../utils';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import { FormikTextInput, Label, Error } from '@simplea/shared-ui';

type Props = {
    participant: ParticipantProps;
    errors: FormikErrors<BankIdConfirmationFormData>;
    hasIssueDate: boolean | '' | null | undefined;
    hasIssuer: boolean;
};

export const IdCard = ({ participant, hasIssueDate, errors, hasIssuer }: Props) => {
    return (
        <>
            <div className="confirmationItemCentered mobileMargin">
                <h3>Doklad</h3>
            </div>
            <DataPlaceholder label="Typ dokladu">{getCardType(participant.IdCards[0].Kind)}</DataPlaceholder>
            <DataPlaceholder label="Číslo dokladu">{participant.IdCards[0].IdCardNo}</DataPlaceholder>
            {hasIssueDate ? (
                <DataPlaceholder label="Vydán kdy" className="mobileMargin">
                    {formatDate(participant.IdCards[0].IssuedDt)}
                </DataPlaceholder>
            ) : (
                <Label htmlFor={BankIdConfirmationFormFields.BirthCountry} label="Vydán kdy" className="mobileMargin">
                    {/* TODO: put DatePicker into component prop to inherit all props */}
                    <Field
                        id={BankIdConfirmationFormFields.IssueDate}
                        name={BankIdConfirmationFormFields.IssueDate}
                        error={errors.issueDate}
                    >
                        {({ field, form }: FieldProps) => (
                            <DatePicker
                                {...field}
                                name={BankIdConfirmationFormFields.IssueDate}
                                onChange={async (value: Date) => {
                                    await form.setFieldValue(field.name, value);
                                }}
                                format="dd-MM-yyyy"
                                disableCalendar
                                dayPlaceholder="dd"
                                monthPlaceholder="mm"
                                yearPlaceholder="rrrr"
                                maxDate={dayjs().year(9999).toDate()}
                                locale="cs-CZ"
                                required
                                className={errors.issueDate ? 'issueDateError' : ''}
                            />
                        )}
                    </Field>
                    <Error isDisplayed={!!errors.issueDate}>{errors.issueDate}</Error>
                </Label>
            )}
            <DataPlaceholder label="Platnost do">{formatDate(participant?.IdCards[0].ExpiryDt)}</DataPlaceholder>
            {hasIssuer ? (
                <DataPlaceholder className="confirmationItemCentered" label="Vydán kým">
                    {participant?.IdCards[0].Issuer}
                </DataPlaceholder>
            ) : (
                <div>
                    <FormikTextInput
                        label="Vydán kým"
                        name={BankIdConfirmationFormFields.Issuer}
                        className="mobileMargin confirmationItemCentered"
                        error={errors.issuer}
                    />
                </div>
            )}
        </>
    );
};
